/**
* Generated automatically at built-time (2024-09-03T15:28:54.995Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "cuisine-d-exterieur-en-espagne",templateKey: "sites/104-1b83b679-674d-4566-8743-9bcd74019825"};