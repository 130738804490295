/**
* Generated automatically at built-time (2024-09-03T15:28:55.128Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "cuisine-d-exterieur-guide-acier",templateKey: "sites/104-96c894c1-9ca7-48dd-8e2f-b14ea6c02272"};